import {createRouter, createWebHistory} from '@ionic/vue-router';
import {RouteRecordRaw} from 'vue-router';
import * as Sentry from "@sentry/vue";

export const routeTree: Array<RouteRecordRaw> = [
    {
        path: '/not-found',
        name: 'invitation_not_found',
        component: () => import ('../views/InvitationNotFound.vue')
    },
    {
        path: '/view/:hash',
        name: 'admin-list',
        component: () => import ('../views/AdminList.vue')
    },
    {
        path: '/:hash',
        name: 'guest-answer',
        component: () => import ('../views/GuestAnswer.vue')
    },
    {
        path: "/:pathMatch(.*)*",
        redirect: '/not-found',
    }
]

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: routeTree
});

Sentry.init({
    dsn: "https://d9562eea9bea02318c6f7f96f3237c35@o1057704.ingest.us.sentry.io/4506916639014912",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
        }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/rueckmeldung\.hoefges\.net\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

export default router
